<div class="items">
    <div class="log-in">
        <a class="classic-button" (click)="closeNavbar()" [href]="loginUrl" autoFocus="false">
            Log in
        </a>
    </div>
    <div class="contact-us">
        <button class="classic-button" routerLink="." fragment="Who-We-Are" (click)="closeNavbar()" autoFocus="false">
            Who We Are
        </button>
        <button class="classic-button" routerLink="." fragment="Why-Choose-Coinigo" (click)="closeNavbar()"
            autoFocus="false">
            Why Coinigo
        </button>
        <button class="classic-button" routerLink="." fragment="How-It-Works" (click)="closeNavbar()" autoFocus="false">
            How It Works
        </button>
        <div class="mt-15">
            <a class="classic-button" href="https://calendly.com/product-coinigo" target="_blank"
                (click)="closeNavbar()" autoFocus="false">
                Free Consultation
            </a>
        </div>
        <button class="classic-button" [routerLink]="['contact-us']">
            Contact us 
        </button>
    </div>
</div>