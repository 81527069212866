<div class="main bg-color ">
    <div class="container">
        <div class="body">
            <div class="items-row">
                <div>
                    <div class="heading-6 text-white text-left">
                        Contact Us
                    </div>
                    <ul class="contact-info">
                        <!-- <li>
                            <img src="/icons/phone.svg" alt="phone">
                            <span>
                                +1 234 567 8910
                            </span>
                        </li> -->
                        <li>
                            <img src="/icons/email.svg" alt="email">
                            <span>
                                partnership&#64;coinigo.com 
                            </span>
                        </li>
                    </ul>
                </div>
                <div>
                    <div class="heading-6 text-white text-left">
                        Our Company
                    </div>
                    <ul class="our-company">
                        <li class="text-white pointer" routerLink="/">
                            <img src="/icons/arrow-right.svg" alt="arrow">
                            <span>
                                Home
                            </span>
                        </li>
                        <li class="text-white pointer">
                            <a href="https://calendly.com/product-coinigo" target="_blank">
                                <img src="/icons/arrow-right.svg" alt="arrow">
                                <span>
                                    Free Consultation
                                </span>
                            </a>
                        </li>
                        <li class="text-white pointer" [routerLink]="['contact-us']">
                            <img src="/icons/arrow-right.svg" alt="arrow">
                            <span>
                                Contact us 
                            </span>
                        </li>
                        <li class="text-white pointer" routerLink="." fragment="FAQs">
                            <img src="/icons/arrow-right.svg" alt="arrow">
                            <span>
                                FAQs
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="items-row">
                <div>
                    <div class="heading-6 text-white text-left">
                        Social Media
                    </div>
                    <ul class="social-media">
                        <li>
                            <div class="icon instagram">
                            </div>
                            <div class="icon facebook">
                            </div>
                            <div class="icon x">
                            </div>
                            <div class="icon youtube">
                            </div>
                            <div class="icon linkedin">
                            </div>
                        </li>
                    </ul>
                </div>
            </div> -->
            <div class="items-row body-1 text-white">
                Copyright &#64;2024. All Rights Reserved
            </div>
        </div>
    </div>
</div>