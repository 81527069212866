import { Component, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-side-nav-item',
  standalone: true,
  imports: [MatButtonModule, RouterLink],
  templateUrl: './side-nav-item.component.html',
  styleUrl: './side-nav-item.component.scss'
})
export class SideNavItemComponent {
  closeSideNav = output<boolean>()
  loginUrl = environment.loginUrl
  closeNavbar() {
    this.closeSideNav.emit(true)
  }
}
