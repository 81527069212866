<div class="main">
    <div class="bg-white container">
        <mat-toolbar>
            <mat-toolbar-row class="header bg-white">
                <div class="flex flex-row items-center gap-32">
                    <div class="logo" routerLink="/">
                        <img src="/logo/CoiniGo-logo-H_on-light-bg.svg">
                    </div>
                    <div class="contact-us">
                        <button class="classic-button" routerLink="." fragment="Who-We-Are">
                            Who We Are
                        </button>
                        <button class="classic-button" routerLink="." fragment="Why-Choose-Coinigo">
                            Why Coinigo
                        </button>
                        <button class="classic-button" routerLink="." fragment="How-It-Works">
                            How It Works
                        </button>
                        <a class="classic-button" href="https://calendly.com/product-coinigo" target="_blank">
                            Free Consultation
                        </a>
                        <button class="classic-button" [routerLink]="['contact-us']">
                            Contact us 
                        </button>
                    </div>
                </div>
                <div class="flex flex-row items-center">

                    <div class="log-in">
                        <a class="classic-button" [href]="loginUrl">
                            Log in
                        </a>
                    </div>
                    <div class="menu-icon" (click)="toggleMenu()">
                        <img src="/icons/menu.svg">
                    </div>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>