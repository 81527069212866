import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './shared/layout/components/footer/footer.component';
import { HeaderComponent } from './shared/layout/components/header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideNavItemComponent } from './shared/layout/components/side-nav-item/side-nav-item.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FooterComponent, HeaderComponent, MatSidenavModule, SideNavItemComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  showMenu: boolean = false

  toggleMenu() {
    this.showMenu = !this.showMenu
  }
}
